import {Component} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'escaperoom-password-website';
  public loginFormData: any = {
    firstname: null,
    password: null
  };
  public formCorrect: boolean;
  public formHasError: boolean;

  public constructor() {
  }

  public submitForm() {
    this.formHasError = false;

    if (this.loginFormData.firstname == 'Janne Jozef Jansen'
      && this.loginFormData.password == 'HollandseKangoeroe1924') {
      this.formCorrect = true;
    } else {
      this.formHasError = true;
    }
  }
}
